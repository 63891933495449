import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import Select from "react-select";
import { AddModGroupProps, AddModGroupState } from "../../../interface/newMenu";
import { secretKey } from "../../../../secret";
import {
  addModGroup,
  groupsListForMultiSelect,
  itemsListForMultiSelect,
  logoutUser,
  menusList,
} from "../../../../redux";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import { addChoice } from "../../../../redux/actions/menuAction";
class AddModGroup extends Component<AddModGroupProps, AddModGroupState> {
  constructor(props: any) {
    super(props);
    this.state = {
      groupJson: [],
      itemsJson: [],
      poscode: null,
      erpid: null,
      modgroupname: "",
      modgroupdesc: "",
      level: "group",
      type: "single",
      priority: "",
      choicesjson: [
        {
          modifier_name: "",
          priority: "",
          modifier_type: "",
          modifier_cost_price: 0,
          modifier_sale_price: "",
          is_publish: 1,
        },
      ],
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleModifiersLevel = this.handleModifiersLevel.bind(this);
  }
  componentDidMount() {
    this.props.groupsList();
    this.props.itemsListForMultiSelect();
    document.title = "Choices";
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleModifiersLevel(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleGroupsInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ itemsJson: [], groupJson: e });
    }
  };
  handleItemsInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ groupJson: [], itemsJson: e });
    }
  };
  isModGroupReady = () => {
    const {
      modgroupname,
      groupJson,
      itemsJson,
      type,
      level,
      priority,
      choicesjson,
    } = this.state;
    console.log(
      modgroupname !== "" &&
        (level == "group" ? groupJson.length > 0 : itemsJson.length > 0) &&
        type != "" &&
        level != "" &&
        priority !== "" &&
        choicesjson.length > 0
    );
    return (
      modgroupname !== "" &&
      (level == "group" ? groupJson.length > 0 : itemsJson.length > 0) &&
      type != "" &&
      level != "" &&
      priority !== "" &&
      choicesjson.length > 0
    );
  };
  handleSaveBtnClick = (event: any) => {
    let {
      groupJson,
      itemsJson,
      modgroupname,
      erpid,
      modgroupdesc,
      poscode,
      type,
      level,
      priority,
      choicesjson,
    } = this.state;
    let data: any = {
      mod_group_name: modgroupname,
      mod_group_description: modgroupdesc,
      type: level,
      modifiers_type: type,
      priority: priority,
      is_publish: 1,
      choices: choicesjson,
    };
    if (level == "group") {
      data["group_json"] = JSON.stringify(groupJson);
    } else if (level == "item") {
      data["items_json"] = JSON.stringify(itemsJson);
    }
    if (poscode !== null) {
      data["pos_code"] = poscode;
    }
    if (erpid !== null) {
      data["erp_id"] = erpid;
    }
    this.props.addChoice(data);
    this.props.setChoiceModal(false);
  };
  handleChoiceInputChange = async (
    event: { target: { name: any; value: any } },
    index: any
  ) => {
    const list: any = this.state.choicesjson;
    list[index][event.target.name] = event.target.value;
    this.setState({ choicesjson: list });
  };
  handleRemoveChoiceClick = (index: any) => {
    const list = this.state.choicesjson;
    list.splice(index, 1);
    this.setState({ choicesjson: list });
  };
  handleAddChoiceClick = (i: any) => {
    const list: any = this.state.choicesjson;
    if (
      list[i]["modifier_name"] == "" ||
      list[i]["modifier_sale_price"] == ""
    ) {
      alert("Please fill in current item");
    } else {
      const newlist: any = {
        modifier_name: "",
        priority: "",
        modifier_type: "",
        modifier_cost_price: 0,
        modifier_sale_price: "",
        is_publish: 1,
      };
      const newMilestoneList: any = this.state.choicesjson.concat(newlist);
      this.setState({ choicesjson: newMilestoneList });
    }
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { isInserted, message, groups, items } = this.props;
    let { choicesjson } = this.state;
    if (!isInserted) {
      msg = message;
      messcolor = "red";
    }
    return (
      <>
        <Modal
          className="fade bd-example-modal-lg"
          show={this.props.choiceModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>Add New Choice</Modal.Title>
            <Button
              variant=""
              className="close"
              onClick={() => this.props.setChoiceModal(false)}
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container defaultActiveKey={"one"}>
              <Nav as="ul" className="nav-pills mb-4 light">
                <Nav.Item as="li" key={1}>
                  <Nav.Link eventKey={"one"}>Info</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" key={2}>
                  <Nav.Link eventKey={"two"}>Choice Level</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" key={3}>
                  <Nav.Link eventKey={"three"}>Choices</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="pt-4">
                <Tab.Pane eventKey={"one"} key={1}>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="form-control-label">
                          Choice Group <span className="text-danger">*</span>
                        </label>
                        <input
                          id="modgroupname"
                          type="text"
                          name="modgroupname"
                          required
                          data-msg="Please enter Modifier Group Name"
                          className="form-control"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-control-label">
                          Choice Type <span className="text-danger">*</span>
                        </label>
                        <select
                          name="type"
                          className="form-control text-capitalize"
                          required
                          data-msg="Please select Type"
                          onChange={this.handleInputChange}
                        >
                          <option value="single">Single Select</option>
                          <option selected={true} value="multiple">
                            Multi Select
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-control-label">
                          Priority<span className="text-danger">*</span>
                        </label>
                        <input
                          id="priority"
                          type="number"
                          name="priority"
                          required
                          data-msg="Please enter Priority"
                          className="form-control"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey={"two"} key={2}>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="form-control-label">
                          Choice Level <span className="text-danger">*</span>
                        </label>
                        <select
                          name="level"
                          className="form-control text-capitalize"
                          required
                          data-msg="Please select Type"
                          onChange={this.handleModifiersLevel}
                        >
                          <option value="group">Category</option>
                          <option value="item">Product</option>
                        </select>
                      </div>
                    </div>
                    {this.state.level === "group" && (
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <label className="form-control-label">
                            Select Categorires{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            isMulti
                            name="groups"
                            options={groups}
                            className="text-capitalize basic-multi-select mt-2"
                            classNamePrefix="select"
                            onChange={(e, i) =>
                              this.handleGroupsInputChange(e, i)
                            }
                          />
                        </div>
                      </div>
                    )}
                    {this.state.level === "item" && (
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <label className="form-control-label">
                            Select Products{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            isMulti
                            name="items"
                            options={items}
                            className="text-capitalize basic-multi-select mt-2"
                            classNamePrefix="select"
                            onChange={(e, i) =>
                              this.handleItemsInputChange(e, i)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey={"three"} key={3}>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        {choicesjson.map((x: any, i: any) => {
                          return (
                            <div>
                              <div key={i} className="row">
                                <div className="col-6">
                                  <div className="form-group">
                                    {/* <label className="form-control-label">Choice Name <span className="text-danger">*</span></label> */}
                                    <input
                                      id="modifier_name"
                                      placeholder="New Choice Name (e.g. Extra Cheese)"
                                      type="text"
                                      name="modifier_name"
                                      value={x.modifier_name}
                                      required
                                      data-msg="Please enter choice name"
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleChoiceInputChange(e, i)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="form-group">
                                    {/* <label className="form-control-label">Price <span className="text-danger">*</span></label> */}
                                    <input
                                      id="modifier_sale_price"
                                      placeholder="New Choice Price"
                                      type="text"
                                      name="modifier_sale_price"
                                      value={x.modifier_sale_price}
                                      required
                                      data-msg="Please enter choice sale price"
                                      className="form-control"
                                      onChange={(e) =>
                                        this.handleChoiceInputChange(e, i)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-2 col-md-2 col-2 text-right mt-2">
                                  {choicesjson.length !== 1 && (
                                    <button
                                      className="btn btn-sm btn-outline-danger"
                                      onClick={() =>
                                        this.handleRemoveChoiceClick(i)
                                      }
                                    >
                                      <i className="fa fa-trash"></i>
                                      {/* {""} */}
                                    </button>
                                  )}
                                  {choicesjson.length - 1 === i && (
                                    <button
                                      className="btn btn-sm btn-primary ml-2"
                                      onClick={() =>
                                        this.handleAddChoiceClick(i)
                                      }
                                    >
                                      <i className="fa fa-plus"></i>
                                      {/* Add Milestone */}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger light"
              onClick={() => this.props.setChoiceModal(false)}
            >
              Close
            </Button>
            <button
              className="btn btn-primary"
              disabled={!this.isModGroupReady()}
              onClick={this.handleSaveBtnClick}
            >
              Save
            </button>

            {/* <Button variant="" type="button" className="btn btn-primary">
                    Save Item
                    </Button> */}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    menus: state.menu.menus,
    groups: state.menu.groupsptions,
    items: state.menu.allActiveItems,
    message: state.menu.message,
    isInserted: state.menu.isInserted,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    addModGroup: function (data: any) {
      dispatch(addModGroup(data));
    },
    addChoice: function (data: any) {
      dispatch(addChoice(data));
    },
    menusList: function () {
      dispatch(menusList());
    },
    groupsList: function () {
      dispatch(groupsListForMultiSelect());
    },
    itemsListForMultiSelect: function () {
      dispatch(itemsListForMultiSelect());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddModGroup);
